import api from '@/utils/request'

// 获取课程信息
export function getCourseInfo(params) {
  return api({
    method: 'get',
    url: '/course/getCourseInfo',
    params,
  })
}

// 获取章节名称
export function chapterDetail(params) {
  return api({
    method: 'get',
    url: '/course/chapterDetail',
    params,
  })
}

export const ConsultationList = (params) =>api({
  method: 'get',
  url: '/information/getInformationArticleDetail',
  params,
})


export const VersionAcquisition = (params) =>api({
  method: 'get',
  url: '/app/version/getVersion',
  params,
})



